import React from 'react';

import Layout from '@src/layouts';

import {
  SectionUnderHeader,
  ContactButtonImageSection,
  BlocksRedirectingToResources,
  BlocksChangingBackgroundColorOnHover,
  NewClientSingleTestimonial,
  BlocksInRowsWithOneActive,
  TechStack,
  ColoredBlocksWithRedirects,
  OpacityScrolledSlider,
  FlipCardsSection,
  MobileHeaderLayoutA,
  DesktopHeaderLayoutB,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  lowLevelProgrammingEngineeringSEOProps,
  headerProps,
  sectionUnderHeaderProps,
  contactButtonImageSectionProps,
  blocksRedirectingToResourcesProps,
  blocksChangingBackgroundColorOnHoverProps,
  testimonialProps,
  blocksInRowsWithOneActiveProps,
  techStackProps,
  coloredBlocksWithRedirectsProps,
  opacityScrolledSliderProps,
  flipCardsSectionProps,
} from '@pages-impl/low-level-programming-engineering/payload';

export default function LowLevelProgrammingEngineering(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Build high-end products with low-level solutions"
      {...props}
    >
      <SEO {...lowLevelProgrammingEngineeringSEOProps} />
      <MobileHeaderLayoutA {...headerProps} titleHasBorder />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeader {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <FlipCardsSection {...flipCardsSectionProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <TechStack {...techStackProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
