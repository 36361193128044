// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--56389";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--067ed";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--fb4a8";
export var coloredBlocksFirstBlock = "payload-module--colored-blocks-first-block--33b5e";
export var coloredBlocksSecondBlock = "payload-module--colored-blocks-second-block--5d68c";
export var contactButtonImageSection = "payload-module--contact-button-image-section--4402b";
export var contactButtonImageSectionCustomContainer = "payload-module--contact-button-image-section-custom-container--3d890";
export var contactButtonImageSectionCustomContent = "payload-module--contact-button-image-section-custom-content--a1fdd";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--6cb54";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--1b957";
export var contactButtonImageSectionOuterWrapper = "payload-module--contact-button-image-section-outer-wrapper--38c03";
export var customCardsContainer = "payload-module--custom-cards-container--4d864";
export var flipCardsSectionInnerWrapper = "payload-module--flip-cards-section-inner-wrapper--a0333";
export var imageContainerUnderHeader = "payload-module--image-container-under-header--467ef";
export var innerWrapperTechStack = "payload-module--inner-wrapper-tech-stack--87043";
export var innerWrapperUnderHeader = "payload-module--inner-wrapper-under-header--3aeac";
export var newParagraph = "payload-module--new-paragraph--54015";
export var section = "payload-module--section--eaf17";
export var subtitleTechStack = "payload-module--subtitle-tech-stack--188b3";
export var titleBorderWidth = "payload-module--title-border-width--4fe3f";
export var titlesContainerTechStack = "payload-module--titles-container-tech-stack--92f55";