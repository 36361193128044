import React from 'react';

import featuredImage from './images/featured-image.jpg';
import contactButtonImageSectionImage from '@commons/contact-button-image-section/images/image3.svg';
import Selector from '@images/logos-partners/Selector-Software-logo.svg';

import { bash, groovy, dpdk, rust, c, go, python } from '@images/logos-techstack-bigger';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './payload.module.scss';

export const lowLevelProgrammingEngineeringSEOProps = {
  title: 'Low-Level Engineering Services - CodiLime Company',
  description:
    'At CodiLime, we have 11+ years of experience supporting clients worldwide with low-level programming challenges.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Low-level
      <br /> programming
      <br /> engineering
    </>
  ),
  titleBorderWidth: styles.titleBorderWidth,
};

export const sectionUnderHeaderProps = {
  descriptionPartOne: (
    <>
      Are you looking for a solution that speeds up a program&apos;s run, and do you care about a small memory
      footprint? Low-level programming engineering can be your way to achieve that!
    </>
  ),
  classNames: { imageContainer: styles.imageContainerUnderHeader, innerWrapper: styles.innerWrapperUnderHeader },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'More about low-level programming on our blog\n',
    subtitle:
      'Do you want to know more about low-level-related cases? You can find more publications on our blog that allow for immersing yourself into this topic.',
    id: 'our-articles',
    classNames: {
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
    },
  },
  blocks: [
    {
      text: 'FPGA in network solutions: benefits and usage examples',
      link: '/blog/fpga-in-network-solutions-benefits-usage/',
    },
    {
      text: 'Rust vs. C: safety and performance in low-level network programming',
      link: '/blog/rust-vs-c-safety-and-performance-in-low-level-network-programming/',
    },
    {
      text: 'How memory types affect DPDK application performance—case study',
      link: '/blog/how-memory-types-affect-dpdk-application-performance/',
    },
    {
      text: 'SmartNICs with P4 support',
      link: '/blog/smartnics-with-p4-support/',
    },
    {
      text: 'FPGA programming – how it works and where can it be used',
      link: '/blog/fpga-programming/',
    },
    {
      text: 'What are SmartNICs? The different types and features',
      link: '/blog/what-are-smartnics-the-different-types-and-features/',
    },
  ],
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'How do we work?',
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      outerWrapper: styles.contactButtonImageSectionOuterWrapper,
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
    id: 'explore-knowledge',
  },
  title: <>Looking for a reliable partner for low-level programming?</>,
  image: contactButtonImageSectionImage,
  imageAlt: 'alt="Get in touch and discover the knowledge within your data',
  isH2: true,
  buttonTitle: 'Schedule a call',
  classNames: {
    customContent: styles.contactButtonImageSectionCustomContent,
    customContainer: styles.contactButtonImageSectionCustomContainer,
    imageContainer: styles.contactButtonImageSectionImageContainer,
  },
};

export const testimonialProps = {
  testimonial: {
    quote: `CodiLime with its expertise in both network engineering and software development helped us build and release a platform for operational intelligence of digital infrastructure. CodiLime UX designers and software engineers did a great job to ensure great user experience of our product. Smooth communication, out-of-the-box thinking and reliability are those factors that also make our cooperation great.`,
    author: 'Rudra Rugge - VP of Engineering',
    logo: Selector,
    logoAlt: 'Selector',
  },
  leftTitle: 'Some words from a client ',
};

export const blocksInRowsWithOneActiveProps = {
  title: 'This is how our low-level engineers will help you',
  subtitle:
    'Low-level programming has been our thing since 2011. Our services aim to provide you with high-quality support whether you need a helping hand with smartNICs, or DPDK, P4, or FPGA development.',
  blocks: [
    {
      title: 'DPDK development',
      description: (
        <>
          Our DPDK-related services include: developing DPDK Poll Mode Drivers for various hardware platforms,
          <b> integration of DPDK drivers with different applications</b>, fixing issues and extending the interfaces
          according to modern PMD driver requirements. You can also rely on us to develop tests to demonstrate precisely
          how switching to DPDK will improve your solution&apos;s performance.
        </>
      ),
    },
    {
      title: 'Services for smartNICs users',
      description: (
        <>
          For <b>companies that want to implement smartNICs</b> into their solutions or products, we offer
          implementation of a particular network function using P4 (if the smartNIC supports it) and adaptation of the
          existing software to control the given smartNIC at runtime.
        </>
      ),
    },
    {
      title: 'Services for smartNIC manufacturers',
      description: (
        <>
          For <b>smartNICs manufacturers</b>, we provide services like developing firmware, dedicated drivers (DPDK PMD
          or kernel module), and QA-building automated test environments for performance and functional tests.
        </>
      ),
    },
    {
      title: 'P4 development',
      description: (
        <>
          Our P4-related services include verifying which P4 architecture model will support the client&apos;s target,
          preparing <b>P4 implementation</b>, and designing the dataplane processing pipeline.{' '}
          <br className={styles.newParagraph} />
          We support creating P4 code compilation, conducting functional tests of dataplanes, and adapting the existing
          control/management software to control the given P4 target at runtime.
        </>
      ),
    },
    {
      title: 'FPGA development',
      description: (
        <>
          Our FPGA team helps you with IP Cores integration and development (VHDL) or HW simulation. You can expect
          support with conducting smoke tests executed directly on hardware, and API level tests of hardware designs. We
          also build for you complete, system-level, dynamic acceptance tests alongside a virtual environment reflecting
          the end user&apos;s use cases. <br className={styles.newParagraph} />
          Want more? <b>We can build full CI/CD pipelines</b> and verify the hardware design in continuous mode with
          full visibility for developers, QA, and managers (domain, software, and hardware knowledge on board).
        </>
      ),
    },
  ],
  classNames: {
    section: styles.section,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
  },
};

export const techStackProps = {
  sectionProps: {
    title: 'Technology stack at CodiLime',
    subtitle: (
      <>
        While developing a low-level project, relying on reliable and trustworthy solutions is essential. Below
        you&apos;ll find <b>our low-level toolkit</b> so that you can check our technology stack!
      </>
    ),
    classNames: {
      titlesContainer: styles.titlesContainerTechStack,
      innerWrapper: styles.innerWrapperTechStack,
      subtitleStyles: styles.subtitleTechStack,
    },
  },
  tilesInLine: 4,
  defaultColor: '#253746',
  data: [rust, c, dpdk, python, go, groovy, bash],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'We have more to offer',
  subtitle: (
    <>
      Besides FGPAs, smartNICs, P4, and DPDK, technologies like{' '}
      <b>Rust and C or C++ are often used to resolve low-level programming-related problems.</b> Check out how we use
      them at CodiLime for our clients.
    </>
  ),
  blocks: [
    {
      text: (
        <>
          <span>Rust development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.RUST,
    },
    {
      text: (
        <>
          <span>C/C++ development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.C_AND_CPP,
    },
    {
      text: (
        <>
          <span>P4 development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.P4,
    },
  ],
  classNames: {
    firstBlock: styles.coloredBlocksFirstBlock,
    secondBlock: styles.coloredBlocksSecondBlock,
  },
};

export const opacityScrolledSliderProps = {
  title: 'What benefits can you expect from low-level engineering?',
  blocksGap: 70,
  shiftDown: 0.1,
  textBlocks: [
    <>
      Better <span>control over the code</span>
    </>,
    <>
      <span>Better data flow management</span> and program optimization
    </>,
    <>
      <span>Higher level of efficiency,</span> even with limited memory and storage
    </>,
  ],
};

export const flipCardsSectionProps = {
  sectionProps: {
    id: 'use-cases',
    title: 'Low-level programming-related use cases',
    subtitle: (
      <>
        <span>
          At CodiLime, we work with manufacturers of smartNICs hardware layers and chips. We also help manufacturers of
          business network solutions or software solutions for hardware acceleration.
        </span>
        <br />
        <br />
        <span>Projects that we are developing include:</span>
      </>
    ),
    classNames: { innerWrapper: styles.flipCardsSectionInnerWrapper },
  },
  customCardsContainerStyles: styles.customCardsContainer,
  flipCardsData: [
    {
      title: 'Updating low-level drivers and libraries to support new smartNICs as a replacement in existing setups',
      color: '#041C2E',
      paddingTop: '47px',
    },
    {
      title: 'Bringing up embedded cores on PCIe devices',
      color: '#053356',
      paddingTop: '97px',
    },
    {
      title: 'Troubleshooting communication with devices on Linux hosts (i.e. on PCIe bus)',
      color: '#122B3F',
      paddingTop: '66px',
    },
    {
      title: 'Creating QEMU setups for embedded systems',
      color: '#021A2D',
      paddingTop: '94px',
    },
  ],
};
